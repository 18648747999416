:root {
  --off-white: #fafafa;
}
.nav {
  background-color: var(--off-white);
  position: sticky;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.nav-logo {
  height: 80px;
  width: 140px;
}

.nav-logo:hover {
  cursor: pointer;
}

.nav .nav-content {
  max-width: 100%;
  padding: 0rem 3rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-item {
  display: inline;
  margin-left: 2rem;
  color: #333;
}

.nav-item:hover {
  color: black;
  cursor: pointer;
}

.section-dark {
  background: #333;
  color: white;
}



.section-content {
  max-width: 100%;
  margin: 0 auto;
  /* padding: 10px; */
  
}

/* start of footer style */
.footer {
  padding: 20px 0px;
  text-align: center;
  background-color: var(--mainBlack);
}

.footer h2 {
  font-size: 30px;
  font-weight: 400;
  color: var(--mainWhite);
}

.footer p {
  font-size: 14px;
  color: var(--mainWhite);
}

.footer p a {
  color: var(--mainWhite);
  text-decoration: none;
}
.footer p a:hover {
  color: var(--primaryColor);
  text-decoration: underline;
  transition: var(--mainTransition);
  -webkit-transition: var(--mainTransition);
  -moz-transition: var(--mainTransition);
  -ms-transition: var(--mainTransition);
  -o-transition: var(--mainTransition);
}

@media screen and (max-width: 776px) {
  .footer h2 {
    font-size: 18px;
  }

  .footer p {
    font-size: 10px;
  }
}
/* end of footer style */